import React, {Fragment}  from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';

import './index.css';

// *** componentes ***  //
import NavBar from './Components/Header';
import Footer from './Components/Footer';
//import Redes from './Components/Redes';

// *** vistas ***  //
import Index from './Views/Index';
import Nosotros from './Views/Nosotros';
import Servicios from './Views/Servicios';
import Contacto from './Views/Contacto';
import Catalogo from './Views/Catalogo';
import Producto from './Views/Producto';
import FormularioPago from './Views/FormularioPago';
import Carrito from './Views/Carrito';
import Memebresisas from './Views/Membresias';

const App = () =>(
    <Provider store={store}>

        <BrowserRouter>
            <Fragment>
                {window.location.pathname.split('/')[1] === 'admin' ? (
                    <span></span>
                ) : (
                    <NavBar />
                )}
                
                {/* vistas */}
                <Route exact path = "/" component = {Index} />
                <Route  path = "/nosotros" component = {Nosotros} />
                <Route  path = "/servicios" component = {Servicios} />
                <Route  path = "/contacto" component = {Contacto} />
                <Route  path = "/destinos" component = {Catalogo} />
                <Route  path = "/producto/:id" component = {Producto} />
                <Route  path = "/carrito" component = {Carrito} />
                <Route  path = "/pagar" component = {FormularioPago} />
                <Route  path = "/membresias" component = {Memebresisas} />
                {/* fin - vistas */}
                {window.location.pathname.split('/')[1] === 'admin' ? (
                    <span></span>
                ) : (
                    <Footer />
                )}
            </Fragment>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
