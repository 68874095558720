import React, {Component} from 'react';
import M from 'materialize-css';
import { Fragment } from 'react';

import img1 from '../Images/logo-01.png';
import img2 from '../Images/logo_03.png';

import icon1 from '../Images/Icons/7.png';
import icon2 from '../Images/Icons/8.png';
import icon3 from '../Images/Icons/10.png';
import icon4 from '../Images/Icons/22.png';
import icon5 from '../Images/Icons/5.png';
import icon6 from '../Images/Icons/6.png';

const Footer = ()=>{
    return(
        <Fragment>
            <footer class="page-footer row" style={{backgroundColor:"white", margin:"0px"}}>
                <div class="col s12 m12 l4 valign-wrapper header_2" style= {{height:"460px"}}>
                    <img src={img1} alt="" style= {{width:"50%"}}/>
                    <img src={img2} alt="" style= {{width:"50%"}} />
                </div>
                <div class="col s12 m12 l8" style= {{backgroundColor:"rgb(0, 40, 73)"}}>
                    <div class="row" style={{padding:"20px 30px", margin:"0px"}} >
                        <div class="col l6 s12">
                            <h5 style= {{color:"rgb(169, 168, 182)",fontWeight:"bold",textAlign:"center"}}>
                                Contáctanos y hagamos ese viaje realidad.
                            </h5>
                            <a target="blanck" href="https://wa.me/523332536608?text=¡Hola buen día! ¿Me puedes brindar información de todos sus servicios?" class="imagen_filter_3">
                                <div style={{display:"flex", alignItems:"center"}}>
                                    <img src={icon1} alt="" style={{width:"50px", height:"50px", margin:"5px"}}/>
                                    <p style={{color:"rgb(169, 168, 182)"}}>
                                        (0133) 3253 6608
                                    </p>
                                </div>
                            </a>
                            <a target="blanck" href="mailto:contacto@tourdiviaggio.com?Subject=Interesado%20en%20los%20servicios.&amp;body=¡Hola%20buen%20día!%0EEstoy%20interesado%20en%20los%20servicios%20que%20ofrecen.%0E¿Me%20podrian%20brindar%20información?" class="imagen_filter_3" >
                                <div  style={{display:"flex", alignItems:"center"}}>
                                    <img src={icon2} alt=""  style={{width:"50px", height:"50px", margin:"5px"}}/>
                                    <p style={{color:"rgb(169, 168, 182)"}}>
                                        contacto@tourdiviaggio.com
                                    </p>
                                </div>
                            </a>
                            <a target="blanck" href="https://g.page/tour-di-viaggio?share" class="imagen_filter_3" >
                                <div  style={{display:"flex", alignItems:"center"}}>
                                    <img src={icon3} alt="" style={{width:"50px", height:"50px", margin:"5px"}}/>
                                    <p style={{color:"rgb(169, 168, 182)"}}>
                                        General Coronado #923, Villaseñor, 44260 Guadalajara, Jal.
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div class="col l4 offset-l2 s12 center">
                            <p style={{color:"rgb(169, 168, 182)", fontSize:"20px"}}>
                                Resolveremos tus dudas en el menor tiempo posible.
                            </p>
                            <div class="padre_2">
                                <div style={{width:"100%"}}>
                                    <input class="input_contacto" type="text" name="nombre" placeholder="NOMBRE" required="" />
                                </div>
                                <div style={{width:"100%"}}>
                                    <input class="input_contacto" type="email" name="email" placeholder="E-MAIL" required="" />
                                </div>
                                <a class="boton_3" href="#!">
                                    <div>
                                        <p>
                                            ENVIAR
                                        </p>
                                    </div>
                                </a>
                            </div>
                            <div style={{marginTop:"25px"}}>
                                <a target="blanck" href="https://www.tiktok.com/@tourdiviaggio" >
                                    <img src={icon4} class="imagen_filter_2" alt="" />
                                </a>
                                <a target="blanck" href="https://www.facebook.com/TourDiViaggio/">
                                    <img src={icon5} class="imagen_filter_2" alt="" />
                                </a>
                                <a target="blanck" href="https://www.instagram.com/TourDiViaggio/">
                                    <img src={icon6} class="imagen_filter_2" alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="footer-copyright">
                        <div class="container">
                            © 2022 Tour Di Viaggio
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
}


export default Footer;