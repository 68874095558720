import React, {Fragment}  from 'react';
import Mapa from '../Components/Mapa';


import icon1 from '../Images/Icons/21.png';
import icon2 from '../Images/Icons/16.png';
import icon3 from '../Images/Icons/17.png';
import icon4 from '../Images/Icons/18.png';
import icon5 from '../Images/Icons/19.png';
import icon6 from '../Images/Icons/20.png';
import icon7 from '../Images/Icons/13.png'; 

const Contacto = () =>{
    return(
        <Fragment>
        <div class="pr_fondo_2">
            <p class="titulo_5">
                Contacto
            </p>
        </div>
        <div class="fondo_2"></div>
        <div class="sombra" style={{position:"relative", top:"-150px", margin:"0px 10%"}}>
            <Mapa />
            <div class="row">
                <div class="col s12 m12 l8" style={{padding:"10px 25px"}} >
                    <p class="titulo_2" style={{textAlign:"center", margin:"25px 0px"}} >
                        Déjanos un mensaje.
                    </p>
                    <form id="formulario_contacto">
                        <div class="row">
                            <div class="col s12 m12 l6">
                                <input class="input_contacto" type="text" name="nombre" placeholder="NOMBRE" required />
                            </div>
                            <div class="col s12 m12 l6">
                                <input class="input_contacto" type="email" name="email" placeholder="E-MAIL" required/>
                            </div>
                            <div class="col s12">
                                <textarea rows="15" cols="50" class="input_contacto" type="text" name="mensaje" placeholder="MENSAJE" required/>
                            </div>
                            <div class="col s12 m6 l6" style={{display:"flex", justifyContent:"center"}}>
                                
                            </div>
                            <button class="boton_3 btn_1" type="submit" name="action" href="#!">
                                <div>
                                    <p>
                                        ENVIAR
                                    </p>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="col s12 m12 l4" style={{background:"rgb(243, 244, 246)", padding:"10px 25px"}}>
                    <div style={{display:"flex", justifyContent:"flex-end", marginTop:"25px"}}>
                        <div>
                            <a target="blanck" href="https://www.tiktok.com/@tourdiviaggio" >
                                <img src={icon1} class="imagen_filter" alt="" />
                            </a>
                            <a target="blanck" href="https://wa.me/523332536608?text=¡Hola buen día! ¿Me puedes brindar información de todos sus servicios?" >
                                <img src={icon2} class="imagen_filter" alt="" />
                            </a>
                            <a target="blanck" href="https://www.facebook.com/TourDiViaggio/" >
                                <img src={icon3} class="imagen_filter" alt="" />
                            </a>
                            <a target="blanck" href="https://www.instagram.com/TourDiViaggio/" >
                                <img src={icon4} class="imagen_filter" alt="" />
                            </a>
                        </div>
                    </div>
                    <p style={{fontSize:"30px"}}>
                        Contáctanos
                    </p>
                    <p>
                        Estamos listos para ayudar a que tu viaje sea inolvidable.
                    </p>
                    <a target="blanck" href="https://wa.me/523332536608?text=¡Hola buen día! ¿Me puedes brindar información de todos sus servicios?" class="imagen_filter_1">
                        <div style={{display:"flex", alignItems:"center"}} >
                            <img src={icon5} alt="" style={{width:"50px", height:"50px", margin:"5px"}}/>
                            <p style={{color:"black", fontSize:"16px"}}>
                                (0133) 3253 6608
                            </p>
                        </div>
                        </a>
                    <a target="blanck" href="mailto:contacto@tourdiviaggio.com?Subject=Interesado%20en%20los%20servicios.&amp;body=¡Hola%20buen%20día!%0EEstoy%20interesado%20en%20los%20servicios%20que%20ofrecen.%0E¿Me%20podrian%20brindar%20información?" class="imagen_filter_1">
                        <div style={{display:"flex", alignItems:"center"}}>
                            <img src={icon6} alt="" style={{width:"50px", height:"50px", margin:"5px"}}/>
                            <p style={{color:"black", fontSize:"16px"}}>
                                contacto@tourdiviaggio.com
                            </p>
                        </div>
                        </a>
                    <a target="blanck" href="https://g.page/tour-di-viaggio?share" class="imagen_filter_1">
                        <div style={{display:"flex", alignItems:"center"}}>
                            <img src={icon7} alt="" style={{width:"50px", height:"50px", margin:"5px"}} />
                            <p style={{color:"black", fontSize:"16px"}}>
                                General Coronado #923, <br />
                                Villaseñor, 44260 Guadalajara, Jal
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        </Fragment>
    );
}

export default Contacto;