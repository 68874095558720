import React, {Fragment, Component, useEffect} from 'react';
import M from 'materialize-css';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import img1 from '../Images/logo_03.png';
import icon1 from '../Images/Icons/5.png';
import icon2 from '../Images/Icons/6.png';
import icon3 from '../Images/Icons/22.png';

const Header = () =>{
    
    useEffect(() => {
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems);
    },[]);

    return(
        <Fragment>
            <nav class="nav" style={{background:"white", boxShadow:"none"}}>
                <div class="movil" style={{background:"rgb(0, 40, 73)", display:"flex", height:"90px", justifyContent:"space-between", paddingLeft:"5%"}}>
                    <div style={{display:"flex"}}>
                        <p>
                            (0133) 3253 6608
                        </p>
                        <p style={{paddingRight:"60px", paddingLeft:"60px"}}>
                            contacto@tourdiviaggio.com
                        </p>
                        <p>
                            General Coronado #923, Villaseñor, 44260 Guadalajara, Jal.
                        </p>
                    </div>
                    <div style={{background:"rgb(154, 216, 214)"}}>
                        <a href="/contacto">
                            <p style={{padding:"0px 30px", color:"black"}}>
                                CONTÁCTANOS
                            </p>
                        </a>
                    </div>
                </div>
                <div class="nav-wrapper" style={{height:"100px", marginTop:"0px"}}>
                    <a href="#!" data-target="mobile-demo" class="sidenav-trigger" style={{zIndex:"10", position:"absolute"}}>
                        <i class="material-icons" style={{color:"black"}}>
                            menu
                        </i>
                    </a>
                    <a href="/" class="sidenav-trigger logo_header">
                        <img src={img1} alt="" style={{width:"80px"}}/>
                    </a>
                    <div class="hide-on-med-and-down" style={{display:"flex", justifyContent:"space-around", alignItems:"center"}}>
                        <a
                        href="/"
                        style={{color:"rgb(0, 40, 73)", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <h3>
                                Tour Di Viaggio
                            </h3>
                        <img src={img1} alt="" style={{width:"80px", marginLeft:"20px"}}/>
                        </a>
                            <ul class="menu">
                                <li><a href="/nosotros">NOSOTROS</a></li>
                                <li><a href="/membresias">MEMBRESIAS</a></li>
                                <li><a href="/destinos">DESTINOS</a></li>
                                <li><a href="/contacto">CONTACTO</a></li>
                            </ul>
                        <div style={{display:"flex"}}>
                            <a target="blanck" href="https://www.facebook.com/TourDiViaggio/">
                                <div class="redes-header">
                                    <img src={icon1} alt="" style={{width:"40px"}}/>
                                </div>
                            </a>
                            <a target="blanck" href="https://www.instagram.com/TourDiViaggio/">
                                <div class="redes-header">
                                    <img src={icon2} alt="" style={{width:"40px"}}/>
                                </div>
                            </a>
                            <a target="blanck" href="https://www.tiktok.com/@tourdiviaggio">
                                <div class="redes-header">
                                    <img src={icon3} alt="" style={{width:"40px"}}/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
            <ul class="sidenav" id="mobile-demo">
                <li><a href="/nosotros">NOSOTROS</a></li>
                <li><a href="/membresias">MEMBRESIAS</a></li>
                <li><a href="/destinos">DESTINOS</a></li>
                <li><a href="/contacto">CONTACTO</a></li>
            </ul>
            <MessengerCustomerChat
                pageId="109407730885964"
                appId="564721608989394"
            />
        </Fragment>
    );

}


export default Header;
