import React, {Fragment, Component}  from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import carritoHelper from "../Helpers/Carrito";

class Carrito extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount = async () => {
        //extraer firestor de props
        const { firestore } = this.props;

        var res = await carritoHelper.obtener(firestore);
        this.setState({
            data: res.data,
            total: res.total
        }, () => {
            console.log(this.state);
        });
    }

    leerDato = e => {
        var data = [...this.state.data];
        data[parseInt(e.target.id)].cantidad = parseInt(e.target.value);
        this.setState({
            data: data
        });
    }

    actualizarCarrito = () => {
        //extraer firestor de props
        const { firestore } = this.props;

        console.log(this.state);
        carritoHelper.actualizar("carrito", firestore, this.state.data);
    }

    render (){
        if (!this.state.data) {
            return (
                <Fragment>
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <div className="container row" style={{marginTop:"50px"}}>
                    <h1>Carrito</h1>
                    <div className="col s12">
                        {this.state.data.map((dato, index) => (
                            <div style={{display:"flex", alignItems:"center"}}>
                                <img src={dato.urlImagen} alt="" style={{width:"150px"}} />
                                <div style={{marginLeft:"20px"}}>
                                    <p>{dato.nombre}</p>
                                    <p>{dato.precio}</p>
                                    <div class="input-field col s6">
                                        <input placeholder="Cantidad" onChange={this.leerDato} id={index} defaultValue={dato.cantidad} name="cantidad" type="text" class="validate" />
                                        <label className="active" for={index}>Cantidad</label>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="col s12 center">
                        <h2>Total: {this.state.total}</h2>
                        <button className="btn" onClick={this.actualizarCarrito}>Actualizar carrito</button>
                        <a href="/pagar" className="btn" style={{marginLeft:"20px"}}>Pagar</a>
                    </div>
                </div>
            )
        }
    }
}

Carrito.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'producto'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        producto: ordered.producto
    }))
)(Carrito);
