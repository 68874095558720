import L from 'leaflet';
import React, {Component} from 'react';


class Mapa extends Component{

    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
        //20.691080206348698, -103.3645720392553
        const position = [20.691080206348698, -103.3645720392553]
        const map = L.map('map').setView(position, 17)
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);
    
        L.marker(position)
          .addTo(map)
          .bindPopup('Tour Di Viaggio')
          .openPopup();
    }

    render(){
        return [
            <div id="mapa" style={{zIndex:"1"}} >
                <div  id="map" className="map"  style={{height:'450px', width:'100%'}}></div>
            </div>
        ]
    }

}

export default Mapa;