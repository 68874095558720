import React from 'react';
import { useState } from 'react';

const Form = () => {
    const [state, setState] = useState({
        nombre:"",
        correo:"",
        mensaje:"",
        fecha: new Date(),
        captcha: false
    });

    const enviar = e => {
        e.preventDefault();
        console.log(state);
    }

    const leerDato = e => {
        e.preventDefault();
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    return (
        <div class="container center" style={{marginTop:"50px"}}>
            <form id="formulario_contacto">
                <div class="row">
                    <div class="col s12 m12 l6">
                        <input class="input_contacto" type="text" name="nombre" placeholder="NOMBRE" required/>
                    </div>
                    <div class="col s12 m12 l6">
                        <input class="input_contacto" type="email" name="correo" placeholder="E-MAIL" required/>
                    </div>
                    <div class="col s12">
                        <textarea rows="15" cols="50" class="input_contacto" type="text" name="mensaje" placeholder="MENSAJE" required></textarea>
                    </div>
                    <button class="boton_3 btn_1" type="submit" name="action">
                        <div>
                            <p>ENVIAR</p>
                        </div>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Form;