import Swal from 'sweetalert2';
import M from 'materialize-css';
import firebaseHelper from './Firebase';

const carritoHelper = {
    agregar: async (collection, firestore, data) => {
        console.log(data.cantidad);
        if (data.cantidad == undefined || data.cantidad == '' || typeof data.cantidad != typeof 1 || Number.isNaN(data.cantidad)) {
            Swal.fire(
                'Error!',
                'Ingrese una cantidad valida',
                'error'
            )
        } else {
            var toastHTML = '<span>Se agregaron ' + data.cantidad + " de " + data.nombre + '</span><button class="btn-flat toast-action">Undo</button>';
            var fecha = new Date();
            var cookie = document.cookie.replace(/(?:(?:^|.*;\s*)_cr\s*\=\s*([^;]*).*$)|^.*$/, "$1");
            var array = {ref: firestore.doc('producto/' + data.id), cantidad: data.cantidad, id: data.id};
            
            if (cookie) {
                var dataBusqueda = await firebaseHelper.buscarId(cookie, "carrito", firestore);

                var dataFind = dataBusqueda.productos.find(dataFind => {
                    if (dataFind.id === data.id) {
                        console.log(dataFind.cantidad);
                        dataFind.cantidad +=  parseInt(data.cantidad);
                        console.log(dataFind.cantidad);
                        return true;
                    }
                });

                if (!dataFind) {
                    dataBusqueda.productos.push(array);
                }

                firestore.update({
                    collection: collection,
                    doc: cookie
                }, dataBusqueda ).then(() => {
                    M.toast({html: toastHTML});
                    document.cookie = "_cr=" + cookie + "; path=/; expires=" + new Date(fecha.setDate(fecha.getDate() + 7));
                });

            } else {
                firestore.add({ 
                    collection: collection 
                }, { productos: [array] }).then(dataInsert => {
                    M.toast({html: toastHTML});
                    document.cookie = "_cr=" + dataInsert.id + "; path=/; expires=" + new Date(fecha.setDate(fecha.getDate() + 7));
                    localStorage._crs = dataInsert.id
                });
            }
        }
    },
    obtener: async (firestore) => {
        var dataList = [], total = 0, iteracion = 0;

        function consulta(doc) {
            return new Promise(async resolve => {
                doc.ref.get()
                .then(res => { 
                    resolve(res.data());
                })
                .catch(err => {
                    //console.error(err)
                    resolve(1);
                });
            })
        }

        async function guardarState(doc) {
            const acceso =  consulta(doc);
            return await acceso;
        }

        async function consulta1(data) {
            return new Promise(async resolve => {
                data.forEach( async (doc, index) => {
                    let resultadoFinal = await guardarState(doc);
                    if (resultadoFinal !== 1) {
                        total += (data[index].cantidad * resultadoFinal.precio);
                        resultadoFinal.cantidad = data[index].cantidad;
                        resultadoFinal.id = data[index].id;
                        dataList.push(resultadoFinal);
                        iteracion++;
                        if (iteracion === data.length) {
                            //console.log(dataList);
                            resolve({data: dataList, total: total});
                        }
                    }
                });
            })
        }

        async function guardarState1(data) {
            const acceso =  consulta1(data);
            return await acceso;
        }

        var cookie = document.cookie.replace(/(?:(?:^|.*;\s*)_cr\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        var dataBusqueda = await firebaseHelper.buscarId(cookie, "carrito", firestore);

        var dataFor = await guardarState1(dataBusqueda.productos);

        return dataFor;
    },
    actualizar: (collection, firestore, data) => {
        var toastHTML = '<span>Se actualizo el carrito</span>';
        var cookie = document.cookie.replace(/(?:(?:^|.*;\s*)_cr\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        var fecha = new Date();
        var items = []
        if (data.length === 0) {
            firestore.delete({
                collection: collection,
                doc: cookie
            }).then(() => {
                document.cookie = "_cr=" + cookie + "; path=/; max-age = 0";
                window.location.reload();
                localStorage.removeItem("_crs");
            })
        } else {
            data.forEach((element, index) => {
                if (element.cantidad !== undefined && element.cantidad !== '' && !Number.isNaN(element.cantidad) && element.cantidad !== 0) {
                    items.push({cantidad: element.cantidad, id: element.id, ref: firestore.doc('producto/' + element.id)})
                }
                if (index === (data.length - 1)) {
                    firestore.update({
                        collection: collection,
                        doc: cookie
                    }, {productos: items} ).then(() => {
                        M.toast({html: toastHTML});
                        document.cookie = "_cr=" + cookie + "; path=/; expires=" + new Date(fecha.setDate(fecha.getDate() + 7));
                        window.location.reload();
                    });
                }
            });
        }
    }

}

export default carritoHelper;