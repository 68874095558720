import React, {Fragment, useEffect, useState}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import ReactHtmlParser from 'react-html-parser';
import M from 'materialize-css';

import icon1 from '../Images/Icons/13.png';
import icon2 from '../Images/Icons/12.png';
import icon3 from '../Images/Icons/14.png';
import icon4 from '../Images/Icons/15.png';

import Form from '../Components/Form';


const Catalogo = (props)=>{

    const [bandera, setBandera] = useState(false);
    const [imagenes, setImagenes] = useState([]);

    useEffect(() => {
        if (props.producto) {
            let { firebase } = props;
            let imagenesArray = [];
            // Get a reference to the storage service, which is used to create references in your storage bucket
            var storage = firebase.storage();

            // Create a storage reference from our storage service
            var storageRef = storage.ref();

            // Create a reference under which you want to list
            var listRef = storageRef.child(`/images/productos/${props.producto.id}/galeria`);

            // Find all the prefixes and items.
            listRef.listAll()
            .then((res) => {
                res.items.map((itemRef, indexRef, arrayRef) => {
                    // All the items under listRef.     
                    storageRef.child(itemRef.fullPath).getDownloadURL()
                    .then((url) => {                        
                        imagenesArray.push({nombre: itemRef.name, url});                      
                        if (indexRef + 1 === arrayRef.length) {                           
                            setImagenes(imagenesArray);
                            setBandera(true);
                            var elems = document.querySelectorAll('.materialboxed');
                            M.Materialbox.init(elems);
                        }
                    })
                    .catch((error) => {
                        // Handle any errors
                    });
                });
            }).catch((error) => {
                // Uh-oh, an error occurred!
            });
        }
    },[props.producto]);

    
    if(!props.producto){
        return (
            <Fragment>
                <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                    <div class="preloader-wrapper big active">
                        <div class="spinner-layer spinner-red darken-4-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }else{
        return(
            <Fragment>
                <div class="container" style={{marginTop:"50px", marginBottom:"100px"}}>
                    <p class="titulo_5 texto_absolute">
                        {props.producto.lugar}
                    </p>
                    <img
                        src={props.producto.urlInformacion}
                        alt="" style={{width:"100%"}}/>
                </div>
                <div class="container center">
                    <div>
                        {ReactHtmlParser(props.producto.informacion)}
                    </div>
                    <p class="titulo_2" style={{marginTop:"100px", marginBottom:"100px"}}>
                        Información básica
                    </p>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <img src={icon1} alt="" style={{width:"50px", height:"50px", margin:"5px"}}/>
                        <p class="titulo_3">
                            País: {props.producto.pais}
                        </p>
                    </div>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <img src={icon2} alt="" style={{width:"50px", height:"50px", margin:"5px"}}/>
                        <p class="titulo_3">
                            Visa: {props.producto.visa}
                        </p>
                    </div>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <img src={icon1} alt="" style={{width:"50px", height:"50px", margin:"5px"}}/>
                        <p class="titulo_3">
                            Ciudad: {props.producto.ciudad}
                        </p>
                    </div>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <img src={icon3} alt="" style={{width:"50px", height:"50px", margin:"5px"}}/>
                        <p class="titulo_3">
                            Lenguaje: {props.producto.lenguaje}
                        </p>
                    </div>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <img src={icon4} alt="" style={{width:"50px", height:"50px", margin:"5px"}}/>
                        <p class="titulo_3">
                            Moneda: {props.producto.moneda}
                        </p>
                    </div>

                    <p class="titulo_2" style={{marginTop:"150px", marginBottom:"50px"}}>
                        Galería
                    </p>
                </div>
                <div class="container" style={{display:"flex", justifyContent:"center", alignItems:"center", flexWrap:"wrap"}}>
                    {!bandera ? (
                        <h2>cargando ...</h2>
                    ) : (
                        imagenes.map(imagen => (
                            <div class="contenedor_galeria">
                                <img class="materialboxed imagen_galeria" src={imagen.url} alt={imagen.nombre} style={{width:"100%"}} />
                            </div>
                        ))
                    )}
                </div>
                <div class="container center" style={{marginTop:"150px"}}>
                    <p class="titulo_1">
                        ¿Te interesa?
                    </p>
                    <p class="titulo_2">
                        Déjanos un mensaje.
                    </p>
                    <Form />
                </div>

            </Fragment>
        );
    }

}



export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'producto',
            doc: props.match.params.id
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        producto: ordered.producto && ordered.producto[0]
    }))
)(Catalogo);