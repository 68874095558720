import React, {Fragment, Component}  from 'react';
import img1 from '../Images/Img/1.png'

const Nosotros = () =>{
    return(
        <Fragment>
                <div class="container center">
                    <p class="titulo_4" style={{fontFamily:"Netherland"}}>
                        Tour <br/>
                        Di <br/>
                        Viaggio
                    </p>
                    <p class="container">
                        Buscamos la satisfacción de nuestros clientes, por eso ponemos toda
                        nuestra estrategia comercial a favor de conseguir tu máxima satisfacción.
                    </p>
                    <p class="container" style={{marginTop:"70px", marginBottom:"70px"}}>
                        Te ayudamos a planificar tus viajes utilizando la última tecnología en la
                        organización de eventos. Garantizamos una experiencia inolvidable.
                    </p>
                    <img src={img1} alt="" style={{width:"100%"}} />
                    <div class="margin_1">
                        <p class="titulo_2" style={{margin:"20px"}}>Misión</p>
                        <p class="container">
                            Ofrecer un servicio personalizado de calidad y confiabilidad, a través
                            de la buena atención de nuestro personal debidamente capacitado,
                            diseñando viajes únicos a precios accesibles, superando las expectativas
                            de nuestros clientes.
                        </p>
                    </div>
                    <div class="margin_1">
                        <p class="titulo_2" style={{margin:"20px"}}>Visión</p>
                        <p class="container">
                            Ser una agencia de viajes reconocida en nuestra región, por la confianza
                            y seguridad que ofrecemos. Presentando innovadores servicios y
                            asegurando una actividad turística estable, promoviendo un ambiente de
                            buenas relaciones y obteniendo la mayor satisfacción de nuestros
                            clientes.
                        </p>
                    </div>
                </div>
            </Fragment>
    );
}

export default Nosotros;
