import React, {Fragment, Component}  from 'react';


import img1 from '../Images/Img/4.png';
import img2 from '../Images/Img/5.png';

import icon1 from '../Images/Icons/1.png';
import icon2 from '../Images/Icons/2.png';
import icon3 from '../Images/Icons/3.png';
import icon4 from '../Images/Icons/4.png';


const Index = props => {
    return(
        <Fragment>
            <div class="fondo"/>
            <div class="container center padding_movil">
                <p class="titulo_1">
                    El mejor de los viajes,
                </p>
                <p class="titulo_2">
                    siempre es el próximo.
                </p>
                <p>
                    Ofrecemos atención personalizada <br/>
                    en todos nuestros servicios
                </p>
            </div>
            <div class="container center padre" style={{marginTop:"0px"}}>
                <div class="hijo">
                    <img class="sombra" src={img1} alt="" style={{width:"100%"}} />
                </div>
                <div class="hijo">
                    <p class="titulo_1">
                        Somos
                    </p>
                    <p class="titulo_2">
                        la empresa
                    </p>
                    <p>
                        que crea la conexión entre las personas <br />con los viajes ideales.
                    </p>
                    <p>
                        Nos encargamos de aerolíneas, hoteles, <br/>
                        cruceros y más…
                    </p>
                    <a class="boton" href="/nosotros">
                        <div>
                            <p>
                                Conócenos
                            </p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="container center padre padding_movil">
                <div class="hijo movil_visible">
                    <img class="sombra" src={img2} alt="" style={{width:"100%"}}/>
                </div>
                <div class="hijo">
                    <p class="titulo_1">
                        Ofreciendo
                    </p>
                    <p class="titulo_2">
                        siempre
                    </p>
                    <p>
                        las mejores experiencias en los viajes a <br/>
                        emprender.
                    </p>
                    <p>
                        Seleccionaremos los aspectos que se<br/>
                        ajusten a tus necesidades para ofrecerte<br/>
                        el mejor viaje al mejor precio.
                    </p>
                    <a class="boton" href="/contacto">
                        <div>
                            <p>
                                Contáctanos
                            </p>
                        </div>
                    </a>
                </div>
                <div class="hijo movil_1">
                    <img class="sombra" src={img2} alt="" style={{width:"100%"}}/>
                </div>
            </div>
            <div class="container padre_2 padding_movil">
                <p class="titulo_2">
                    ¿Por qué reservar con nosotros?
                </p>
                <div class="servicios_index">
                    <div class="padre">
                        <img src={icon1} alt="" style={{width:"100px"}} />
                        <p>
                            Servicio personalizado
                        </p>
                    </div>
                    <div class="padre">
                        <img src={icon2} alt="" style={{width:"100px"}} />
                        <p>
                            Servicio personalizado
                        </p>
                    </div>
                    <div class="padre">
                        <img src={icon3} alt="" style={{width:"100px"}} />
                        <p>
                            Compras seguras
                        </p>
                    </div>
                    <div class="padre">
                        <img src={icon4} alt="" style={{width:"100px"}} />
                        <p>
                            Mejor precio garantizado
                        </p>
                    </div>
                </div>
            </div>
            <div class="padre_2" style={{margin:"100px 0px 50px"}}>
                <p class="titulo_2">
                    Destino
                </p>
                <div class="container" style={{display:"flex", justifyContent:"center", alignItems:"center", flexWrap:"wrap"}}>
                    <div class="padre_3">
                        <div class="hijo_3" style={{marginTop:"50px"}}>
                            <a href="/producto/QvQ7FIVD6P2WL4U5oSdX">
                                <div class="lugares">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/tour-di-viaggio.appspot.com/o/images%2Fproductos%2FQvQ7FIVD6P2WL4U5oSdX%2FQvQ7FIVD6P2WL4U5oSdX?alt=media&amp;token=60454cd9-daaf-4057-af27-fbc3d6ccb8b7" alt="" style={{width:"100%"}}/>
                                    <p style={{fontSize:"30px", color:"black", margin:"20px 0px"}} >
                                        La Habana
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div class="hijo_3" style={{marginTop:"50px"}}>
                            <a href="/producto/KjffUkhOqxwF33FSd2Nw">
                                <div class="lugares">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/tour-di-viaggio.appspot.com/o/images%2Fproductos%2FKjffUkhOqxwF33FSd2Nw%2FKjffUkhOqxwF33FSd2Nw?alt=media&amp;token=06911949-4f09-4013-83f9-e616dceda36e" alt="" style={{width:"100%"}}/>
                                    <p style={{fontSize:"30px", color:"black", margin:"20px 0px"}}>
                                        París
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div class="hijo_3" style={{marginTop:"50px"}}>
                            <a href="/producto/lR1WehwWIb45SmjIRnfi">
                                <div class="lugares">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/tour-di-viaggio.appspot.com/o/images%2Fproductos%2FlR1WehwWIb45SmjIRnfi%2FlR1WehwWIb45SmjIRnfi?alt=media&amp;token=31de6485-44da-4860-afe1-105b832cb3f2" alt="" style={{width:"100%"}}/>
                                    <p style={{fontSize:"30px", color:"black", margin:"20px 0px"}}>
                                        Cabo San Lucas
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <a class="boton_2" href="/destinos">
                    <div>
                        <p>
                            Ver más…
                        </p>
                    </div>
                </a>
            </div>
        </Fragment>
    );
}

export default Index;
