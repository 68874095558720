import React, {Fragment, useEffect, Component, useState}  from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import Swal from 'sweetalert2';

import gris from '../Images/gris.png';

const Catalogo = (props) =>{

    const [producto, setProducto] = useState([]);
    const [vaijeSeleccionado, setViajeSeleccionado] = useState([]);
    const [indexViaje, setViaje] = useState(0);
    const [bandera, setBandera] = useState(false);
 
    useEffect(() => {
        if(props.producto){
            let arrayTem = [];
            let arrayProd = [];
            let indexViaje = 0;
            props.producto.map((producto, index, array) =>{
                if (index !== 0) {
                    if (producto.categoria !== array[index -1].categoria) {
                        let categoria = props.categoria.find(categoria => categoria.id === producto.categoria);
                        arrayTem.push({data: producto, titulo: categoria.nombre});
                    } else {
                        arrayTem.push({data: producto});
                    }
                } else {
                    let categoria = props.categoria.find(categoria => categoria.id === producto.categoria);
                    arrayTem.push({data: producto, titulo: categoria.nombre});
                }
                indexViaje = indexViaje + 1;
                if(indexViaje % 6 === 0 && indexViaje !== 0){
                    arrayProd.push(arrayTem);
                    arrayTem = [];
                    indexViaje = 0;
                }else if (index + 1 === array.length) {
                    arrayProd.push(arrayTem);
                    arrayTem = [];
                }
            });
            setProducto(arrayProd);
            setViajeSeleccionado(arrayProd[0]);
            setBandera(true);
        }   
    }, [props.categoria]);

    const mostrarMas = clic => {
        if (producto.length === indexViaje + 1 && !producto[indexViaje + 1]) {
            Swal.fire(
                'Se acabaron',
                'Son todos los viajes disponibles',
                'success'
            )
        } else {
            let masproducto = vaijeSeleccionado;
            let index = indexViaje + 1;
            producto[index].map(curso => {
                masproducto.push(curso);
            });
            setViajeSeleccionado(masproducto);
            setViaje(index);
        }
    }


    if(!props.producto){
        return(
            <Fragment>
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
        );
    }else{
        return(
            <Fragment>
                <div>
                    <p className='center titulo_2' style={{margin:"40px 0%"}}>
                        Destinos
                    </p>
                    <div className='container'>
                        <div className='padre_3'>
                            {!bandera ? (
                                <h2>cargando...</h2>
                            ) : (
                                vaijeSeleccionado.map(destino => (
                                    <Fragment>
                                        {destino.titulo &&
                                            <p style={{width:"100%"}}>{destino.titulo}</p>
                                        }
                                        <div className='hijo_3'>
                                            <a href={`/producto/${destino.data.id}`}>
                                                <div className='lugares'>
                                                    <p className='titulo_destiunos' style={{textAlign:"center"}}>
                                                        <span className='titulo_2' style={{fontSize:"20px"}}>Desde</span><br />
                                                        {destino.data.precio}
                                                    </p> 
                                                    <img src={destino.data.urlImagen} style={{width:"100%"}} />
                                                    <p className='titulo_destiunos'>
                                                        {destino.data.lugar}
                                                    </p>      
                                                </div>
                                            </a>  
                                        </div>
                                    </Fragment>
                                ))
                            )}
                            
                        </div>
                        
                        
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <button class="boton_2" onClick={ ()=>{mostrarMas()}}>
                            <div>
                                <p>
                                    Ver más…
                                </p>
                            </div>
                        </button>
                        
                    </div>

                </div>


            </Fragment>
        );
    }
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'producto',
            orderBy:"categoria"
        },
        {
            collection: 'categoria'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        producto: ordered.producto,
        categoria: ordered.categoria
    }))
)(Catalogo);
