import React, {Fragment}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import CryptoJS from "react-native-crypto-js";

import img1 from '../Images/Img/15.png';
import img2 from '../Images/Img/19.png';
import img3 from '../Images/Img/18.png';

import Form from '../Components/Form';

const Membresias = (props) =>{

    const pagar = (e, nombre, precio) => {
        e.preventDefault();
        const data = {
            nombre,
            precio
        }

        let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'Y2Fhdg==').toString();
        sessionStorage.setItem("data", ciphertext);

        window.location.replace("/pagar");
    }
    


    if(!props.membresias){
        return (
            <Fragment>
                <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                    <div class="preloader-wrapper big active">
                        <div class="spinner-layer spinner-red darken-4-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }else{
        return(
            <Fragment>
                <div class="pr_fondo_1">
                    <p class="titulo_5">
                        Membresías
                    </p>
                </div>
                <div class="fondo_1"/>
                <div class="container">
                    <div class="container center" style={{marginTop:"100px", marginBottom:"100px"}}>
                        <p class="titulo_1">
                            Adquiere alguna de nuestras
                        </p>
                        <p class="titulo_2">
                            tres membresías
                        </p>
                        <p>
                            Permite acceder a diversas promociones exclusivas y tarifas
                            preferenciales con descuentos en todos los servicios turísticos tanto
                            nacional como internacional.
                        </p>
                        <p>
                            Esto significa que, con tu membresía, recibirás un servicio integral,
                            pero sobre todo de calidad.
                        </p>
                    </div>
                    <div class="padre_1">
                        <div class="hijo_1 sombra membresias_1 center">
                            <p class="titulo_1" style={{fontWeight:"bold"}}>
                                Membresía
                            </p>
                            <p class="titulo_2">
                                BÁSICA
                            </p>
                            <p>
                                Descuento adicional sobre las tarifas
                            </p>
                            <p>
                                Vigencia de 1 año
                            </p>
                            <p>
                                Membresía personal
                            </p>
                            <button onClick={e => {pagar(e, "Membresia Básica", props.membresias.basica)}} class="boton_3">
                                <div>
                                    <p>
                                        $ {props.membresias.basica} MXN
                                    </p>
                                </div>
                            </button>
                        </div>
                        <div class="hijo_2">
                            <img src={img1} alt="" style={{width:"100%"}} />
                        </div>
                    </div>
                    <div class="padre_1 margin_membresias">
                        <div class="hijo_2">
                            <img src={img2} alt="" style={{width:"100%"}} />
                        </div>
                        <div class="hijo_1 sombra membresias_2 center">
                            <p class="titulo_1"style={{fontWeight:"bold"}}>
                                Membresía
                            </p>
                            <p class="titulo_2">
                                PLUS
                            </p>
                            <p>
                                Descuento hasta del 5%
                            </p>
                            <p>
                                Vigencia de 1 año
                            </p>
                            <p>
                                Membresía para hasta 2 adicionales
                            </p>
                            <button onClick={e => {pagar(e, "Membresia Plus", props.membresias.plus)}} class="boton_3">
                                <div>
                                    <p>
                                        $ {props.membresias.plus} MXN
                                    </p>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="padre_1" style={{marginBottom:"200px"}}>
                        <div class="hijo_1 sombra membresias_1 center">
                            <p class="titulo_1"style={{fontWeight:"bold"}}>
                                Membresía
                            </p>
                            <p class="titulo_2">
                                INTERNACIONAL
                            </p>
                            <p>
                                Descuento hasta del 10%
                            </p>
                            <p>
                                Vigencia de 1 año
                            </p>
                            <p>
                                Membresía para hasta 5 adicionales
                            </p>
                            <button onClick={e => {pagar(e, "Membresia Internacional", props.membresias.internacional)}} class="boton_3">
                                <div>
                                    <p>
                                        $ {props.membresias.internacional} MXN
                                    </p>
                                </div>
                            </button>
                        </div>
                        <div class="hijo_2">
                            <img src={img3} alt="" style={{width:"100%"}} />
                        </div>
                    </div>
                </div>
                <div class="container center" style={{marginTop:"100px"}}>
                    <p class="titulo_1">
                        ¿Te interesa?
                    </p>
                    <p class="titulo_2">
                        Déjanos un mensaje.
                    </p>
                    <Form />
                </div>
            </Fragment>
        );
    }


    
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'membresias'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        membresias: ordered.membresias && ordered.membresias[0]
    }))
)(Membresias);



